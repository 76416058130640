body {  
  font-family: Roboto, 'Open Sans' 
}

.AppCounder {
    display: flex;
    height: 100%;
    background-color: #18191b;
    color: #ffffff;
    position: fixed;
    width: 100%;
    font-family: 'Roboto', sans-serif
  }

  .splash-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #18191b;
    color: #ffffff;
    z-index: 120;
  }

  .splash-refresh {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background-color: #18191b95;
    color: #ffffff;
    z-index: 120;
  }

  .header-pane {
    width: 100%;
    height: 55px;
    background-color: #282a2f;
    display: flex;
    border-bottom: 1px solid #1e1f22;
    justify-content: space-evenly;
    position: fixed;
    top: 0%;
    left: 0%;
    z-index: 2;
    align-items: center;
  }

  .header-pane .search-bar {
    width: calc(100% - 360px - 70px);
    background-color: #282a2f;
  }

  .header-pane .menu {
      display: block;
  }

  .header-pane .jobs-tab {
    display: none;
  }
  
  .leftPane {
    min-width: fit-content;
    max-width: fit-content;
    transition: .22s transform;
    overflow: hidden;
    /* border-right: 1px solid #3f4147; */
    background-color: #2b2d31;
    display: -webkit-inline-box;
    flex-direction: column;
  }


  .topicBar {
    height: 100%;
    width: 68px;
    overflow-y: auto;
    background-color: #1e1f22;
    border-right: 2px solid rgb(42, 42, 42);
  }
  
  .left-inner-pane {
    width: 320px;
    margin-top: 55px;
    padding: 10px;
    height: "auto";
    overflow-y: auto;
    background-color: #1e1f22
  }

  .left-inner-pane .top-chat-section {
    padding: 10px;
    max-height: 60px;
    min-height: 60px;
    text-align: left;
    vertical-align: middle;
    width: 100%;
    font-weight: bold;
    font-size: 20px;
  }
 

  .topic {
    padding: 10px;
    border-radius: 50%;
    background-color: #4b4b4b;
    margin: 10px;
    text-align: center;
  }
  
  .inquiries {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .centerPane {
    background-color: #1e1f22; /*"transparent"; */
    width: calc(100% - 360px - 70px);
    padding-left: 5px;
    padding-right: 5px;
    position: relative;
    /* box-shadow: 0px 10px 10px -2px rgba(0,0,0,0.75); */
    margin-top: 55px;
    text-align: left;
    background-image: url('../../images/type.webp');
    background-repeat: repeat;
    background-size: auto;
  }

  .centerPane .center-text {
    text-align: center;
    font-size: 36px;
    font-weight: 800;
    color: rgb(56,56,56);
    position: absolute;
    top:40%;
    left: 41%;
    font-family: Arial, Helvetica, sans-serif;
  }

  .centerPane .new-tab {
     display: none;
  }  

  .centerPane .refresh-tab {
    display: inline-flex;
    margin-left: 10px;
    border-radius: 50%;
    background-color: #26272a;
    padding: 2px;
    /* padding-left: 13px;
    padding-right: 13px; */
    z-index: 200;
 }

 .centerPane .refresh-tab-color {
    /* background-color: #ffa87d; */
 }

 .thinking {
    display: none;
    margin-left: 10px;
    border-radius: 10px;
    background-color: #26272a;
    padding: 4px;
    padding-left: 13px;
    padding-right: 13px;
    position: fixed;
    left: 20px;
    bottom: 130px;
 }

  .get-job {
      background-color: #585b61;
      display: inline-flex;
      padding: 3px;
      padding-left:6px;
      padding-right: 6px;
      margin-right: 6px;
      border-radius: 8px;
      margin-bottom: 10px;
  }

  .execute-job {
    background-color: #6262d8;
    display: inline-flex;
    padding: 8px;
    padding-left:16px;
    padding-right: 16px;
    margin-right: 6px;
    border-radius: 20px;
    margin-bottom: 10px;
}
  
  .rightPane {
    /* width: calc(40% - 350px); */
    width: 450px;
    background-color: #1e1f22;  /*#313338; */
    overflow-y: auto;
    position: sticky;
    right: 0;
    top: 0;
    margin-top: 55px;
  }

  .rightPane .right-title {
    padding: 10px;
    padding-left: 20px;
    border: 1px solid rgb(52, 52, 52);  
    color: white;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
    font-size: 18px;
  }

  .job-section {
    background-color: #2a2b2f;
    padding: 10px;
    margin: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
    text-justify: auto;
  }

  .job-task {
    background-color: #383a40;
    padding: 10px;
    margin: 10px;
    margin-bottom: 10px;
    border-radius: 8px;
  }
  
  .bottomPane {
    position: absolute;
    border-top: 1px solid #383a40;
    bottom: 0;
    left: 0px;
    width: calc(100% - 0px);
    padding-left: 40px;
    padding-right: 80px;
    background-color: #1b1c1e;
    padding: 10px;
    padding-top: 30px;
    min-height: 150px;
  }
  
  .bottomPane textarea {
    width: calc(100% - 150px);
    padding: 20px;
    border: none;
    resize: none;
    background-color: #24262d; /* #383a40; */
    color: #ffffff;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 40px;
    border:1px solid rgb(52, 52, 52);
    border-radius: 15px;
    max-height: 50px;
    max-width: 950px;
    unicode-bidi: isolate;
    -webkit-user-modify: read-write;
    overflow-wrap: break-word;
    vertical-align: center;
    overflow-y: hidden;
  }

  .bottomPane textarea:focus {
    outline: none;
  }

  .bottomPane .loading {
    position: absolute;
    width: 100%;
    top: -10px;
    font-size: 11px;
  }
  
  .bottomPane button {
    width: 40px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: #7289da;
    color: #ffffff;
    position: absolute;
    right: 15px;
    margin-top: 10px;
  }  

  .newChat {
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    border: 1px solid rgb(52, 52, 52);  
    background-color: #26272a; /* Slightly darker color for new chat button */
    color: white;
    text-align: left;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 10px;
  }

  div {
    font-family: 'Arial', 'Helvetica', sans-serif;
    font-size: 16px;
  }
  
  /* Links/Highlights */
  a, .highlight {
    color: #007BFF;
  }
 

  input:focus {
    scroll-padding-top: 40px; /* Adjust based on your needs */
  }
  

  @media (max-width: 600px) {
    @media screen and (orientation: landscape) {
      body {
        transform: rotate(-90deg);
        transform-origin: left top;
        width: 100vh;
        height: 100vw;
        overflow-x: hidden;
        position: absolute;
        top: 100%;
        left: 0;
      }
    }    

    .AppCounder {
        height: 100%;
        background-color: #313338;
        color: #ffffff;;
    }
  
    .header-pane {
        width: 100%;
        height: 55px;
        background-color: #18191b;
        display: flex;
        border-bottom: 1px solid #1e1f22;
        justify-content: space-evenly;
        position: fixed;
        top: 0%;
        left: 0%;
        z-index: 2;
        align-items: center;
    }
  
    .header-pane .search-bar {
        width: calc(100% - 360px - 70px);
        background-color: #282a2f;
        display: none;
    }

    .header-pane .menu {
        display: block;
    }

    .header-pane .jobs-tab {
        display: inline-flex;
        margin-left: 10px;
        border-radius: 10px;
        background-color: #6262d8;
        padding: 4px;
        padding-left: 13px;
        padding-right: 13px;
        position: absolute;
        right: 70px;
        top: 10px;

    }
    
    .leftPane {
        min-width: fit-content;
        max-width: fit-content;
        transition: .22s transform;
        overflow: hidden;
        border-right: 1px solid #3f4147;
        background-color: #2b2d31;
        display: none;
        flex-direction: column;
    }
  
  
    .topicBar {
        height: 100%;
        width: 68px;
        overflow-y: auto;
        background-color: #1e1f22;
        border-right: 2px solid rgb(42, 42, 42);
        display: none;
    }
    
    .left-inner-pane {
        width: 320px;
        margin-top: 55px;
        padding: 10px;
        display: none;
        background-color: #24262d;
    }
  
    .left-inner-pane .top-chat-section {
        padding: 10px;
        max-height: 60px;
        min-height: 60px;
        text-align: left;
        vertical-align: middle;
        width: 100%;
        font-weight: bold;
        font-size: 20px;
    }
   
  
    .topic {
        padding: 10px;
        border-radius: 50%;
        background-color: #4b4b4b;
        margin: 10px;
        text-align: center;
    }
    
    .inquiries {
        flex-grow: 1;
        overflow-y: auto;
    }
    
    .centerPane {
        background-color: #1e1f22; /*"transparent"; */
        width: 100%;
        padding-left: 5px;
        padding-right: 5px;
        position: relative;
        box-shadow: 0px 10px 10px -2px rgba(0,0,0,0.75);
        margin-top: 55px;
        text-align: left;
    }
  
    .centerPane .center-text {
        text-align: center;
        font-size: 36px;
        font-weight: 800;
        color: rgb(56,56,56);
        position: absolute;
        top:40%;
        left: 41%;
        font-family: Arial, Helvetica, sans-serif;
    }

    .centerPane .new-tab {
        display: inline-flex;
        margin-left: 10px;
        border-radius: 50%;
        background-color: #26272a;
        font-size: 30px;
        padding: 4px;
        /* padding-left: 17px;
        padding-right: 17px; */
        position: fixed;
        bottom: 250px;
        right: 5px;
        z-index: 100;
   }

   .centerPane .refresh-tab {
        position: fixed;
        bottom: 130px;
        right: 5px;
        z-index: 300;
   }

   .thinking {
      top: 10px;
      bottom: unset;
      z-index: 9020;
   }
 
    
    .rightPane {
        width: 100%;
        background-color: #1e1f22;
        overflow-y: auto;
        position: sticky;
        right: 0;
        top: 0;
        margin-top: 55px;
        display: none;
    }
  
    .rightPane .right-title {
        padding: 10px;
        padding-left: 20px;
        border: 1px solid rgb(52, 52, 52);  
        color: white;
        text-align: left;
        cursor: pointer;
        border-radius: 10px;
        font-size: 18px;
    }
    
    .bottomPane {
        position: absolute;
        bottom: 10px;
        left: 0px;
        padding-left: 20px;
        padding-right: 20px;
        width: calc(100% - 0px);
        margin-left: 0px;
        margin-right: 0px;
        background-color: #1b1c1e;
        padding-top: 10px;
        min-height: 80px;
        z-index: 2;
    }
    
    .bottomPane textarea {
        width: calc(100% - 50px);
        padding: 10px;
        border: none;
        resize: none;
        background-color: #24262d; /* #383a40; */
        color: #ffffff;
        border-radius: 5px;
        margin-left: 0px;
        margin-right: 0px;
        padding-top: 15px;
        padding-bottom: 10px;
        border:1px solid rgb(52, 52, 52);
        border-radius: 15px;
        min-height: 30px;
        max-height: 20px;
        unicode-bidi: isolate;
        -webkit-user-modify: read-write;
        overflow-wrap: break-word;
        vertical-align: center;
        overflow-y: hidden;
    }

    .bottomPane .loading {
        position: absolute;
        left: 0%; 
        width: 100%;
        top: -0px;
    }
    
    .bottomPane button {
        width: 40px;
        padding: 10px;
        padding-left: right 15px;
        padding-right: 15px;
        border: none;
        border-radius: 50%;
        background-color: #7289da;
        color: #ffffff;
        position: absolute;
        right: 10px;
        margin-top: 10px;
        bottom: 20px
    }  
  
    .newChat {
        padding: 10px;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid rgb(52, 52, 52);  
        background-color: #26272a; /* Slightly darker color for new chat button */
        color: white;
        text-align: left;
        cursor: pointer;
        border-radius: 10px;
    }  
  }



  .rotate-emoji {
    animation: rotate 2s infinite linear;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.23);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .pulse {
      animation: pulse 2s infinite;
  }

  @keyframes scaleDot {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
  
  .animated-dot {
    animation: scaleDot 3.5s infinite;
    transform-origin: center;
    opacity: 0.5;
  }